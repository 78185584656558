import React from "react";

const Header = () => {
  return (
    <header className="header text-center p-0 bg-light">
        <nav className="navbar navbar-expand-sm bg-body-tertiary">
            <div className="container-fluid">
                <h1>
                    <a className="navbar-brand" href="/">Adiggle Song List</a>
                </h1>
            </div>
        </nav>
    </header>
  );
};

export default Header;
