import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header from "./components/Header";
import Footer from "./components/Footer";

const Songs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    // 正しいAPIエンドポイントを設定
    const fetchChannels = async () => {
      try {
        const response = await axios.get('/api/index');
        setChannels(response.data);
      } catch (error) {
        console.error('There was an error fetching the data!', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchChannels();
  }, []);
  
  const channelClick = (channelId) => {
    navigate(`/videos/${channelId}`);
  };
  
  if (isLoading) {
    // ロード中の場合はローディング画面
    return (
      <>
        <h1>Loading...</h1>
      </>
    );
  } else {
    return (
      <div className="container">
        <div className="sticky-top">
          <Header />
        </div>
        <main>
        <h4>チャンネル一覧</h4>
        <table>
          <tbody>
        {channels.map((channel, index) => (
          <tr className="channel" onClick={() => channelClick(`${channel.channelId}`)} key={index}>
            <td><img src={`data:image/jpeg;base64,${channel.thumbnails}`} alt={channel.title} /></td>
            <td>{channel.title}</td>
          </tr>
          ))}
          </tbody>
        </table>
        </main>
        <Footer />
      </div>
    );
  }
};

export default Songs;
