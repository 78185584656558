import React from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";

const About = () => {
  return (
    <div className="container">
      <div className="sticky-top">
        <Header />
      </div>
      <main>
      <div class="content">
        <p>
          当サイトはファンが非公式に運営しているモノです。<br />
          <font color="red"><b>当サイトについて配信者様本人ならびに運営様への問い合わせ等はご遠慮ください。</b></font><br />
          <br />
          当サイトのデータベースはYoutubeコメントに記載されている情報をもとに作成されています。<br />
          AIによる自動更新のため、誤った情報が登録されている可能性があります。
        </p>
      </div>

      </main>
      <Footer />
    </div>
  );
};

export default About;
