import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./Home";
import Videos from "./Videos";
import About from "./About";
import Meta from './components/Meta';
import "./style.css";
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <Router>
      <Meta />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/videos/:channelId" element={<Videos />} />
        </Routes>      
      </div>
    </Router>
  );
};

export default App;
