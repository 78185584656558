import React from "react";

const Footer = () => {
  return (
    <footer className="footer text-center text-white p-1 bg-secondary">
        <a className="unstyled text-white" href="/about">このサイトについて</a>
        <br />
        <p className="unstyled text-white">©︎2024 <a className="unstyled text-white" target="_blank" href="https://x.com/yuckey_m">yuckey</a></p>
    </footer>
  );
};

export default Footer;