import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from "./components/Header";
import Footer from "./components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';

const Videos = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [songs, setSongs] = useState([]);
  const [videos, setVideos] = useState([]);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const {channelId} = useParams();

  const fetchSongs = useCallback(async (channelId, query, page = 1) => {
    try {
      const response = await axios.get('/api/videos', {
        params: {
          channelId: channelId,
          searchValue:query,
          page:page
        }
      });
      setSongs(response.data.songs);
      setVideos(response.data.videos);
      setTotalPages(response.data.totalPages);
    } catch(error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const toggleSongs = (videoId) => {
    const updatedSongs = songs.map(song => 
      song.videoId === videoId ? { ...song, visible: !song.visible } : song
    );
    setSongs(updatedSongs);
  };

  useEffect(() => {
    if (channelId) {
      fetchSongs(channelId, query, page);
    }
  }, [channelId, query, page, fetchSongs]);

  const handleSearchInputChange = (event) => {
    setPage(1);
    setQuery(event.target.value);
  };

  const handlePageClick = (newPage) => {
    setPage(newPage);
  };

  if (isLoading) {
    // ロード中の場合はローディング画面
    return (
      <>
        <h1>Loading...</h1>
      </>
    );
  } else {
    return (
      <div className="container">
        <div className="sticky-top">
          <Header />
          <div className="bg-light">
            <form onSubmit={(e) => e.preventDefault()}>
            <input
                id="search-input"
                type="text"
                name="searchValue"
                value={query}
                placeholder="タイトルまたはアーティストを検索"
                data-channelid={channelId}
                onChange={handleSearchInputChange}
              />
            </form>
          </div>
        </div>
        <main>
        <h4>歌一覧</h4>
          <div id="-songresults">
            {videos.map((video) => (
              <div key={video.videoId} id="video" className="video">
                <div className="videoArea" onClick={() => toggleSongs(video.videoId)}>
                  <div className="thumbnail"><img src={`data:image/jpeg;base64,${video.thumbnails}`} alt="thumbnail" /></div>
                  <div className="title">{video.title}</div>
                  <div className="publishedAt">{video.publishedAt}</div>
                </div>
                <div className="songArea">
                {songs.filter(song => song.videoId === video.videoId).map((song) => (
                    <div key={song.sec} id="song" className="song" data-videoid={song.videoId} style={{ display: song.visible ? 'block' : 'none' }}>
                      <a href={song.url} target="_blank" rel="noopener noreferrer">
                        <div>{song.title} / {song.artist}</div>
                      </a>
                  </div>
                ))}
                </div>
              </div>
            ))}
          </div>
          <div className="container-fluid">
            <div className="row">
              <nav aria-label="Page navigation">
                <ul className="pagination justify-content-center">
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="First" onClick={() => handlePageClick(1)}>
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  {Array.from({ length: Math.min(totalPages, 5) }, (_, i) => {
                    let startPage = Math.max(1, page - 2);
                    let endPage = Math.min(totalPages, page + 2);
                    
                    let range = [];
                    for (let j = startPage; j <= endPage; j++) {
                      range.push(j);
                    }

                    while (range.length < 5) {
                      if (range[0] > 1) {
                        range.unshift(range[0] - 1);
                      } else if (range[range.length - 1] < totalPages) {
                        range.push(range[range.length - 1] + 1);
                      } else {
                        break;
                      }
                    }

                    return range[i];
                  }).map((p) => (
                    <li key={p} className={`page-item ${p === page ? 'active' : ''}`}>
                      <a className="page-link" href="#" onClick={() => handlePageClick(p)}>{p}</a>
                    </li>
                  ))}
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Last" onClick={() => handlePageClick(totalPages)}>
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>

          </div>
        </main>
        <Footer />
      </div>

    );
  }
};

export default Videos;
