import React from 'react';
import { Helmet } from 'react-helmet';

const Meta = () => {
  return (
    <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta property="og:url" content="https://www.adi-song.net/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Adiggle Song List" />
        <meta property="og:description" content="Adiggle所属メンバーの歌枠をメンバーごとに掲載しています。曲名・アーティスト名で検索が可能です。" />
        <meta property="og:site_name" content="Adiggle Song List" />
        <meta property="og:locale" content="ja_JP" />
        <meta property="og:image" content="https://i.gyazo.com/edcf83a72f069788fd5f2ab701f4f7d0.png" />
        <link rel="icon" href="favicon.ico"/>
        <title>Adiggle Song List</title>
    </Helmet>
  );
};

export default Meta;
